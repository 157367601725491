exports.components = {
  "component---src-pages-404-js": () => import("./../../../src/pages/404.js" /* webpackChunkName: "component---src-pages-404-js" */),
  "component---src-pages-index-js": () => import("./../../../src/pages/index.js" /* webpackChunkName: "component---src-pages-index-js" */),
  "component---src-templates-affiliate-redirect-js": () => import("./../../../src/templates/affiliate-redirect.js" /* webpackChunkName: "component---src-templates-affiliate-redirect-js" */),
  "component---src-templates-author-index-js": () => import("./../../../src/templates/authorIndex.js" /* webpackChunkName: "component---src-templates-author-index-js" */),
  "component---src-templates-author-js": () => import("./../../../src/templates/author.js" /* webpackChunkName: "component---src-templates-author-js" */),
  "component---src-templates-blog-index-js": () => import("./../../../src/templates/blog-index.js" /* webpackChunkName: "component---src-templates-blog-index-js" */),
  "component---src-templates-brand-index-js": () => import("./../../../src/templates/brand-index.js" /* webpackChunkName: "component---src-templates-brand-index-js" */),
  "component---src-templates-page-js": () => import("./../../../src/templates/page.js" /* webpackChunkName: "component---src-templates-page-js" */),
  "component---src-templates-post-js": () => import("./../../../src/templates/post.js" /* webpackChunkName: "component---src-templates-post-js" */),
  "component---src-templates-review-js": () => import("./../../../src/templates/review.js" /* webpackChunkName: "component---src-templates-review-js" */)
}

